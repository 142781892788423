import axios from 'axios'
import { ElMessage } from 'element-plus'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    return config // 必须返回配置
  },
  // 请求失败
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  // 请求成功
  response => {
    const { success, msg, data, code } = response.data
    // 判断请求是否成功
    if (success) {
      return data
    } else if (code === 200) {
      return response.data
    } else {
      // 失败（请求成功，业务失败），消息提示
      ElMessage({
        message: msg,
        type: 'error'
      })
      return Promise.reject(new Error(msg))
    }
  },

  // 请求失败
  error => {
    return Promise.reject(new Error(error))
  }
)

export default service
