export const TOKENKEY = 'Qy-Token'

export const TOKEN = 'token'
// token 时间戳
export const TIME_STAMP = 'timeStamp'
// 超时时长(毫秒) 两小时
export const TOKEN_TIMEOUT_VALUE = 2 * 3600 * 1000

// 跳转地址
export const QYDOMAIN = 'qyDomain'
