import router from '@/router'
import store from '@/store'

import { getToken } from '@/utils/cookie.js'

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta.desc) {
    const meta = document.querySelector('meta[name=description]')
    meta.content = to.meta.desc
  }
  if (getToken()) {
    if (to.path === '/login' || to.path === '/register') {
      next('/')
    } else {
      // 判断用户资料是否获取
      // 若不存在用户信息，则需要获取用户信息
      if (!store.getters.hasUserInfo) {
        // 触发获取用户信息的 action，并获取用户当前权限
        await store.dispatch('user/getUserInfo')
      }
      next()
    }
  } else {
    next()
  }
})
