import { Login, getInfo, Loginout } from '@/api/sys'

import router from '@/router'
import { QYDOMAIN } from '@/constant/index'
import { setTimeStamp } from '@/utils/auth'
import { getToken } from '@/utils/cookie'
import { setItem, getItem, removeAllItem } from '@/utils/storage'

export default {
  namespaced: true,
  state: () => ({
    token: getToken() || '',
    qyDomain: getItem(QYDOMAIN) || '',
    userInfo: {}
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setQyDomain(state, qyDomain) {
      state.qyDomain = qyDomain
      setItem(QYDOMAIN, qyDomain)
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    }
  },
  actions: {
    // 登录请求
    login(context, userInfo) {
      return new Promise((resolve, reject) => {
        Login(userInfo)
          .then(res => {
            //
            this.commit('user/setQyDomain', `${res.qyDomain}/#/index`)
            // 跳转
            location.href = `${res.qyDomain}/#/index`
            // 保存登录时间
            setTimeStamp()
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    // 获取用户信息
    async getUserInfo(context) {
      const res = await getInfo()
      this.commit('user/setUserInfo', res.user)
      return res
    },

    // 用户退出
    logout() {
      return new Promise((resolve, reject) => {
        Loginout()
          .then(() => {
            this.commit('user/setQyDomain', '')
            this.commit('user/setUserInfo', {})
            removeAllItem()
            // TODO:清理掉权限相关配置
            router.push('/login')
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  }
}
