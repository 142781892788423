/**
 * 判断是否为外部资源
 * @param {*} path
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * 判断是否为正确的手机号
 * @param {*} num
 */
export function isPhone(num) {
  return /^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(num)
}
