import request from '@/utils/request'
import { getToken } from '@/utils/cookie'

// 注册 获取手机短信
export const getCaptcha = params => {
  return request({
    url: '/h5/captcha',
    method: 'GET',
    params
  })
}

// 注册 校验验证码
export const postCaptcha = data => {
  return request({
    url: '/h5/captcha',
    method: 'POST',
    data
  })
}

// 注册 完善账号信息
export const postPassword = (data, token) => {
  return request({
    headers: {
      Authorization: `Bearer ${token}`
    },
    url: '/h5/password',
    method: 'POST',
    data
  })
}

// 登录 账密登录
export const Login = data => {
  return request({
    url: '/h5/login',
    method: 'POST',
    data
  })
}

// 退出
export const Loginout = data => {
  return request({
    headers: {
      Authorization: `Bearer ${getToken()}`
    },
    url: '/loginout',
    method: 'POST',
    data
  })
}

// 用户信息
export const getInfo = params => {
  return request({
    headers: {
      Authorization: `Bearer ${getToken()}`
    },
    url: '/getInfo',
    method: 'GET',
    params
  })
}

// 找回密码发送短信
export const getRetrievePasswordCaptcha = params => {
  return request({
    url: '/h5/password/captcha',
    method: 'GET',
    params
  })
}
// 找回密码验证短信
export const postRetrievePasswordCaptcha = data => {
  return request({
    url: '/h5/password/captcha',
    method: 'POST',
    data
  })
}
