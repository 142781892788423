import SvgIcon from '@/components/SvgIcon'

// 1. 导入所有的svg图标
const svgRequire = require.context('./svg', false, /\.svg$/)
svgRequire.keys().forEach(svgIcon => svgRequire(svgIcon))

// 2. 完成SvgIcon的全局注册
export default app => {
  app.component('svg-icon', SvgIcon)
}
